import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Select,
  message,
  Checkbox,
  Slider,
  InputNumber,
  Radio,
  Tooltip,
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import {
  valuesSetHandler,
  paramsReturnHandler,
  CompensationType,
  jobFunctions,
  jobCategories,
} from '../../../data'
import {
  cleanObject,
  getDescription,
  permissionsHandler,
} from '../../../functions'
import { useSelector, useDispatch } from 'react-redux'
import { strapiURL } from '../../../config'
import { navigate } from 'gatsby'
import axios from 'axios'
import GooglePlacesAutocomplete from '../../google-place-autocomplete'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { getAddressObject } from '../../../functions'
import { setProducts } from '../../../redux/actions/user'
import { CloseOutlined } from '@ant-design/icons'
import useGMT from '../../../hooks/useGTM'
import EmployerJobPreview from '../../Modals/employerJobPreview'

const FORM_METHOD_SUBMIT = 'FORM_METHOD_SUBMIT'
const FORM_METHOD_DRAFT = 'FORM_METHOD_DRAFT'
const FORM_METHOD_PREVIEW = 'FORM_METHOD_PREVIEW'

const JobCreateComponent = ({ user, userProfile, location }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [draftLoading, setDraftLoading] = useState(false)
  const [minMaxSalary, setMinMaxSalary] = useState([0, 0])
  const [compensation, setCompensation] = useState([0, 0])
  const [formMethod, setFormMethod] = useState(FORM_METHOD_SUBMIT)
  const [currentJob, setCurrentJob] = useState({})
  const [locationRequired, setLocationRequired] = useState(true)
  const [externalRequired, setExternalRequired] = useState(false)
  const [jobPreview, setJobPreview] = useState()
  const [form] = Form.useForm()
  const { Option } = Select
  const [jobLocationList, setJobLocationList] = useState([])
  const [applicationMethod, setApplicationMethod] = useState('')
  const userData = useSelector(state => state.user)
  const dispatch = useDispatch()
  const storeProducts = useSelector(state => state.user.products)
  let products = permissionsHandler(storeProducts, user?.role?.id)
  const { pushPostJobEvent } = useGMT()

  let ReactQuill
  if (typeof window !== 'undefined') {
    ReactQuill = require('react-quill')
  }

  useEffect(() => {
    if (location.search) {
      let _search = paramsReturnHandler(location.search)
      if (_search.parent_id) {
        axios.get(strapiURL + `/jobs/${_search.parent_id}`).then(res => {
          if (_search?.store_parent) {
            setCurrentJob(res?.data)
          }
          setMinMaxSalary([res?.data?.minSalary, res?.data?.maxSalary])
          setCompensation([
            res?.data?.min_compensation,
            res?.data?.max_compensation,
          ])
          if (Array.isArray(res?.data?.location)) {
            setJobLocationList(
              (res?.data?.location || []).map(item => item.placePredict)
            )
          } else {
            if (res?.data?.location) {
              setJobLocationList([res?.data?.location?.placePredict])
            } else {
              setJobLocationList([])
            }
          }
          if (res?.data?.application_method) {
            setApplicationMethod(res?.data?.application_method)
          }
          form.setFieldsValue({
            title: res?.data?.title || '',
            description: res?.data?.description || '',
            job_code: res?.data?.job_code || '',
            remote_enabled: res?.data?.remote_enabled || false,
            field_based: res?.data?.field_based || false,
            exclusive_medreps: res?.data?.exclusive_medreps || false,
            min_max: [res?.data?.minSalary, res?.data?.maxSalary],
            compensation: [
              res?.data?.min_compensation,
              res?.data?.max_compensation,
            ],
            primary_function: res?.data?.primary_function || '',
            product_category: res?.data?.product_category || '',
            compensation_type: res?.data?.compensation_type || '',
            travel_percentage: res?.data?.travel_percentage || '',
            application_method: res?.data?.application_method || '',
            application_email: res?.data?.application_email || '',
            applicationUrl: res?.data?.applicationUrl || '',
          })

          if (res?.data?.remote_enabled) {
            setLocationRequired(false)
          }
        })
      }
    }
  }, [location])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.hubspot_web_interactives_running = false
      var script = document.createElement('script')
      script.src = 'https://js.hubspot.com/web-interactives-embed.js'
      document.body.appendChild(script)
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      // min_max: [150, 400],
      description: '',
    })
  }, [])

  const submitHandler = async values => {
    if (
      formMethod === FORM_METHOD_SUBMIT &&
      !products?.subscriptionPurchased &&
      !products?.singleJob
    ) {
      message.info('Please purchase a subscription to post or update jobs.')
      return
    }

    if (compensation[0] === 0 && compensation[1] === 0) {
      message.error('Average Total Compensation is required')
      return
    }
    if (compensation[0] > compensation[1]) {
      message.error(
        'Minimum compensation cannot be greater than maximum compensation'
      )
      return
    }
    if (compensation[1] > 500) {
      message.error('Maximum compensation cannot exceed $500k')
      return
    }
    if (minMaxSalary[0] > minMaxSalary[1]) {
      message.error('Minimum salary cannot be greater than maximum salary')
      return
    }
    if (values.applicationUrl && !values.applicationUrl.includes('http')) {
      form.setFields([
        {
          name: 'applicationUrl',
          errors: ['Enter a valid http or https url.'],
        },
      ])
      return
    }
    if (formMethod === FORM_METHOD_DRAFT) {
      setDraftLoading(true)
    } else if (formMethod === FORM_METHOD_SUBMIT) {
      setSubmitLoading(true)
    }

    let createdJob = {
      ...values,
      employer: userProfile?.id,
      minSalary: minMaxSalary[0],
      maxSalary: minMaxSalary[1],
      min_compensation: compensation[0],
      max_compensation: compensation[1],
      draft: formMethod === FORM_METHOD_DRAFT,
      is_template: false,
      posted_by_name: user?.user?.username,
      posted_by_id: user?.user?.auth0_user_id,
      userById: user?.user?.id,
      description: getDescription(values.description),
    }

    /* Location Process */
    const locationList = []
    if (jobLocationList && jobLocationList.length > 0) {
      for (const locItem of jobLocationList) {
        const geocodeData = await geocodeByPlaceId(locItem.value.place_id)
        if (geocodeData[0]) {
          const addressObject = getAddressObject(
            geocodeData[0].address_components
          )
          const locationObject = {
            location: {
              ...addressObject,
              formattedAddress: geocodeData[0].formatted_address,
              latitude: geocodeData[0].geometry.location.lat(),
              longitude: geocodeData[0].geometry.location.lng(),
              place_id: locItem.value.place_id,
            },
            placePredict: locItem,
          }
          locationList.push(locationObject)
        }
      }
    }

    createdJob.location = locationList

    if (formMethod === FORM_METHOD_PREVIEW) {
      setJobPreview(createdJob)
      return
    }

    if (currentJob) {
      createdJob.parent_job = currentJob.id
    }

    axios
      .post(strapiURL + '/jobs', createdJob)
      .then(res => {
        pushPostJobEvent({
          jobid: res.data.id,
          jobIndustry: res.data.product_category,
          jobPostingDate: res.data.created_at,
          jobFunction: res.data.primary_function,
          jobLocation: res.data.location?.[0]?.location?.formattedAddress || '',
        })
        if (res.data.draft && formMethod === FORM_METHOD_DRAFT) {
          message.success(
            'Your job has been saved as a draft. Please review and click "Publish Job" to set it live'
          )
          navigate(`/employer-dashboard/jobs/manage/`, location)
        } else if (res.data.draft) {
          message.info({
            content:
              'Job saved as draft, please review your pricing plan to start posting jobs.',
            duration: 8,
          })
          navigate(`/employer-dashboard/account/`)
        } else {
          const userId =
            user?.role?.name === 'Named User'
              ? userData?.profileData?.user?.id
              : user?.user?.id
          axios
            .get(strapiURL + `/employers/${userId}/subscriptionsAndCredits`)
            .then(responsee => {
              dispatch(setProducts(responsee?.data))
              message.success(
                'Your job has been published and will be live on MedReps shortly.'
              )
              navigate('/employer-dashboard/jobs/manage/', location)
            })
            .catch(err => {
              setDraftLoading(false)
              setSubmitLoading(false)

              message.error(
                err?.response?.data?.message || 'Something went wrong.'
              )
            })
        }
      })
      .catch(err => {
        setDraftLoading(false)
        setSubmitLoading(false)
        message.error(err?.response?.data?.message || 'Something went wrong.')
      })
  }

  const handleRemoveLoc = index => {
    jobLocationList.splice(index, 1)
    setJobLocationList([...jobLocationList])
  }

  return (
    <div>
      <h1 className="font-playfair text-headingThree text-merlot mt-6 lg:mt-0">
        Post A New Job
      </h1>
      <div>
        <Form
          className="pt-8 lg:pt-12 w-12/12 lg:w-8/12"
          form={form}
          name="create-job-form"
          layout="vertical"
          onFinish={submitHandler}
          initialValues={{
            application_email: user?.username,
          }}
        >
          <Form.Item
            name="title"
            label="Job Title"
            className="theme-form-item mb-8"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Enter Job Title"
              className="w-full placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
            />
          </Form.Item>

          <Form.Item
            className="theme-form-item w-full mb-8"
            name="description"
            label="Job Description"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            {typeof window !== 'undefined' && (
              <ReactQuill
                defaultValue=""
                className="quill-editor job-editor placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                theme="snow"
                placeholder="Enter Job Description"
              />
            )}
          </Form.Item>

          <Form.Item
            name="job_code"
            label="Job Code"
            className="theme-form-item w-full mb-8"
          >
            <Input
              type="text"
              placeholder="Enter Job Code"
              className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
            />
          </Form.Item>

          <div className="flex">
            <Form.Item
              name="remote_enabled"
              label=""
              className="theme-form-item w-full mb-8"
              valuePropName="checked"
            >
              <Checkbox
                onChange={e => {
                  if (e.target.checked) {
                    form.setFieldsValue({
                      location: null,
                    })
                    setLocationRequired(false)
                  } else {
                    setLocationRequired(true)
                  }
                }}
                className="search-filter-dropdown-item"
              >
                Remote
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="field_based"
              label=""
              className="theme-form-item w-full mb-8"
              valuePropName="checked"
            >
              <Checkbox className="search-filter-dropdown-item">Field</Checkbox>
            </Form.Item>
            <Tooltip title="Check this box if this job appears only MedReps and is not posted to other websites.">
              <Form.Item
                name="exclusive_medreps"
                label=""
                className="theme-form-item w-full mb-8"
                valuePropName="checked"
              >
                <Checkbox className="search-filter-dropdown-item">
                  Exclusive To Medreps
                </Checkbox>
              </Form.Item>
            </Tooltip>
          </div>

          <Form.Item
            name="location"
            label="Location"
            className="google-place-autocomplete w-full mb-8"
            rules={[
              {
                required: locationRequired && jobLocationList.length === 0,
                message: 'Required.',
              },
            ]}
          >
            <div className="flex flex-wrap">
              {jobLocationList.map((locItem, index) => (
                <div className="border py-2 px-4 rounded-full flex items-center mr-4 mb-2">
                  <span className="mr-4">{locItem?.label}</span>

                  <CloseOutlined onClick={() => handleRemoveLoc(index)} />
                </div>
              ))}
            </div>
            <GooglePlacesAutocomplete
              value={null}
              onChange={val => {
                if (jobLocationList.length < 5 && val) {
                  jobLocationList.push(val)
                  setJobLocationList([...jobLocationList])
                }
              }}
            />
          </Form.Item>

          <Form.Item
            className="theme-form-item w-full mb-8"
            name="compensation_type"
            label="Compensation Type"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Select placeholder="Select Compensation Type">
              {CompensationType &&
                CompensationType?.map((primary, index) => {
                  return (
                    <Option key={index} value={valuesSetHandler(primary)}>
                      {primary}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="compensation"
            label=""
            className="theme-form-item w-full mb-8 multiple-slider"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Required.',
            //   },
            // ]}
          >
            <div className="flex items-center justify-between">
              <div className="ant-col  ant-form-item-label flex-1	">
                <label className=" font-semibold text-sm font-sans ant-form-item-required">
                  Average Total Compensation
                </label>
              </div>

              <span className="font-semibold text-sm font-sans">
                ${compensation[0]}K - ${compensation[1]}K
              </span>
            </div>
            <div className="mt-8">
              <Slider
                className="hidden lg:block"
                onChange={val => {
                  setCompensation(val)
                  form.setFieldsValue({
                    compensation: val,
                  })
                }}
                range
                value={compensation}
                max={500}
                min={0}
              />
              <div className="flex items-center mt-8">
                <InputNumber
                  value={compensation[0] || 0}
                  min={0}
                  max={500}
                  type="number"
                  onChange={e => {
                    let _val = parseInt(e)
                    let _minMaxCopy = [...compensation]
                    if (_val) {
                      _minMaxCopy[0] = _val
                    } else {
                      _minMaxCopy[0] = 0
                    }
                    setCompensation(_minMaxCopy)
                  }}
                  placeholder="Enter Minimum Compensation"
                  className="relative min-value w-4/12 placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                />

                <InputNumber
                  value={compensation[1]}
                  type="number"
                  min={0}
                  max={500}
                  placeholder="Enter Maximum Compensation"
                  className="relative max-value ml-8 w-4/12 placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                  onChange={e => {
                    let _val = parseInt(e)
                    let _minMaxCopy = [...compensation]
                    if (_val) {
                      _minMaxCopy[1] = _val
                    } else {
                      _minMaxCopy[1] = 0
                    }
                    setCompensation(_minMaxCopy)
                  }}
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item
            name="min_max"
            label=""
            className="theme-form-item w-full mb-8 multiple-slider"
          >
            <div className="flex items-center justify-between">
              <span className="font-semibold text-sm font-sans">
                Base Salary
              </span>

              <span className="font-semibold text-sm font-sans">
                ${minMaxSalary[0]}K - ${minMaxSalary[1]}K
              </span>
            </div>
            <div className="mt-8">
              <Slider
                className="hidden lg:block"
                onChange={val => {
                  setMinMaxSalary(val)
                }}
                range
                value={minMaxSalary}
                max={500}
                min={0}
              />
              <div className="flex items-center mt-8">
                <InputNumber
                  value={minMaxSalary[0] || 0}
                  min={0}
                  max={500}
                  type="number"
                  onChange={e => {
                    let _val = parseInt(e)
                    let _minMaxCopy = [...minMaxSalary]
                    if (_val) {
                      _minMaxCopy[0] = _val
                    } else {
                      _minMaxCopy[0] = 0
                    }
                    setMinMaxSalary(_minMaxCopy)
                  }}
                  placeholder="Enter Minimum Salary"
                  className="relative min-value w-4/12 placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                />
                <InputNumber
                  value={minMaxSalary[1]}
                  type="number"
                  min={0}
                  max={500}
                  placeholder="Enter Maximum Salary"
                  className="relative max-value ml-8 w-4/12 placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                  onChange={e => {
                    let _val = parseInt(e)
                    let _minMaxCopy = [...minMaxSalary]
                    if (_val) {
                      _minMaxCopy[1] = _val
                    } else {
                      _minMaxCopy[1] = 0
                    }
                    setMinMaxSalary(_minMaxCopy)
                  }}
                />
              </div>
            </div>
          </Form.Item>

          <Form.Item
            className="theme-form-item w-full mb-8"
            name="primary_function"
            label="Function"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Select placeholder="Select Function">
              {jobFunctions &&
                jobFunctions?.map((primary, index) => {
                  return (
                    <Option key={index} value={valuesSetHandler(primary)}>
                      {primary}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item
            className="theme-form-item w-full mb-8"
            name="product_category"
            label="Industry"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Select placeholder="Select Industry">
              {jobCategories &&
                jobCategories?.map((primary, index) => {
                  return (
                    <Option key={index} value={valuesSetHandler(primary)}>
                      {primary}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Travel Percentage"
            name="travel_percentage"
            className="theme-form-item mb-8"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Radio.Group>
              <Radio
                className="search-compensation-radio text-charcoal font-sans w-[40%] pb-4 lg:w-max lg:pt-0"
                value="0_25%"
              >
                0 -25%
              </Radio>
              <Radio
                className="search-compensation-radio font-sans w-[40%] pb-4 lg:w-max lg:pt-0"
                value="25_50%"
              >
                25-50%
              </Radio>
              <Radio
                className="search-compensation-radio  font-sans w-[40%] lg:w-max lg:pt-0"
                value="50_75%"
              >
                50-75%
              </Radio>
              <Radio
                className="search-compensation-radio  font-sans w-[40%] lg:w-max lg:pt-0"
                value="75_100%"
              >
                75-100%
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Application Method"
            name="application_method"
            className="theme-form-item mb-8"
            onChange={e => {
              setApplicationMethod(e.target.value)
            }}
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Radio.Group>
              <Radio
                className="search-compensation-radio  font-sans"
                value="externalRedirect"
              >
                External Redirect
              </Radio>
              <Radio
                className="search-compensation-radio  font-sans"
                value="email"
              >
                Email
              </Radio>
            </Radio.Group>
          </Form.Item>

          {applicationMethod === 'externalRedirect' && (
            <Form.Item
              name="applicationUrl"
              label=""
              className="theme-form-item w-full mb-8"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
                {
                  type: 'url',
                  message: 'Enter a valid http or https url.',
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Application URL"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
          )}
          {applicationMethod === 'email' && (
            <Form.Item
              name="application_email"
              className="theme-form-item w-full mb-8"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
                {
                  type: 'email',
                  message: 'Invalid email address entered',
                },
              ]}
            >
              <Input
                type="text"
                // defaultValue={userProfile?.username}
                placeholder="Enter Email Address For Applications"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
          )}

          <div className="flex mt-4 w-full">
            <button
              onClick={() => {
                setFormMethod(FORM_METHOD_SUBMIT)
                form.submit()
              }}
              type="button"
              className="bg-voyage py-3 px-10 text-white font-bold rounded text-sm font-sans mr-4"
              disabled={draftLoading || submitLoading}
            >
              {submitLoading ? 'Posting...' : 'Post Job'}
            </button>
            <button
              onClick={() => {
                setFormMethod(FORM_METHOD_DRAFT)
                form.submit()
              }}
              type="button"
              className="bg-bgSecondaryButton py-3 px-10 text-voyage font-bold rounded text-sm font-sans"
              disabled={draftLoading || submitLoading}
            >
              {draftLoading ? 'Saving...' : 'Save As Draft'}
            </button>
            <button
              onClick={() => {
                setFormMethod(FORM_METHOD_PREVIEW)
                form.submit()
              }}
              type="button"
              className="bg-voyage py-3 px-10 text-white font-bold rounded text-sm font-sans ml-4"
            >
              Preview
            </button>
          </div>
        </Form>
      </div>
      {!!jobPreview && (
        <EmployerJobPreview
          modalOpen={!!jobPreview}
          currentJob={jobPreview}
          currentJobEmployer={userProfile}
          onClose={() => setJobPreview(false)}
        />
      )}
    </div>
  )
}
export default JobCreateComponent
